<template>
  <section class="panel pd-20">
    <aPageHeader class="mb-10 pd-0">
      <template #title>
        <h1>Dashboard</h1>
      </template>
    </aPageHeader>

    <div class="modules">
      <div
        class="module"
        v-if="
          $root.isAdmin() ||
          $root.isAnalyst() ||
          $root.isManager() ||
          $root.isUser() ||
          $root.isMilesManager()
        "
      >
        <div class="card contratos" @click="$router.push(`/pipeline`)">
          <div class="ico" style="left: -25px; top: -35px; font-size: 128px">
            <a-icon type="fire" />
          </div>
          <div class="name">
            <span v-if="$root.isAdmin() || $root.isMilesManager()">
              <span v-if="totalOpportunities > 0" class="count">{{
                totalOpportunities
              }}</span>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalOpportunities == 1">Oportunidade</span>
            <span v-else>Oportunidades</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        v-if="
          $root.isAdmin() ||
          $root.isAnalyst() ||
          $root.isManager() ||
          $root.isUser() ||
          $root.isMilesManager()
        "
      >
        <div class="card contratos" @click="$router.push(`/contracts/list`)">
          <div class="ico" style="left: -15px; top: -35px; font-size: 128px">
            <a-icon type="file-protect" />
          </div>
          <div class="name">
            <span v-if="$root.isAdmin() || $root.isMilesManager()">
              <font v-if="totalContracts > 0" class="count">{{
                totalContracts
              }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalContracts == 1">Contrato</span>
            <span v-else>Contratos</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
        v-if="$root.isAdmin() || $root.isManager() || $root.isMilesManager()"
      >
        <div class="card contratos" @click="$router.push(`/suppliers/list`)">
          <div class="ico" style="left: -20px; top: -50px; font-size: 148px">
            <a-icon type="team" theme="outlined" />
          </div>
          <div class="name">
            <span>
              <font v-if="totalSuppliers > 0" class="count">{{
                totalSuppliers
              }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalSuppliers == 1">Fornecedor</span>
            <span v-else>Fornecedores</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
        v-if="$root.isAdmin() || $root.isManager() || $root.isMilesManager()"
      >
        <div class="card contratos" @click="$router.push(`/miles-cards/list`)">
          <div class="ico" style="left: -14px; top: -45px; font-size: 148px">
            <a-icon type="credit-card" theme="outlined" />
          </div>
          <div class="name">
            <span>
              <font v-if="totalCards > 0" class="count">{{ totalCards }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalCards == 1">Cartão Milhas</span>
            <span v-else>Cartão Milhas</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
      >
        <div class="card contratos" @click="$router.push(`/events/calendar`)">
          <div class="ico" style="left: -14px; top: -45px; font-size: 148px">
            <a-icon type="calendar" theme="outlined" />
          </div>
          <div class="name">
            <span v-if="$root.isAdmin()">
              <font v-if="totalEvents > 0" class="count">{{
                totalEvents
              }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalEvents == 1">ATIVIDADE</span>
            <span v-else>ATIVIDADES</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
        v-if="$root.isAdmin() || $root.isManager() || $root.isAnalyst()"
      >
        <div class="card contratos" @click="$router.push(`/sales/list`)">
          <div class="ico" style="left: -24px; top: -45px; font-size: 148px">
            <a-icon type="shopping" theme="outlined" />
          </div>
          <div class="name">
            <span v-if="!$root.isAnalyst()">
              <span v-if="totalSales > 0" class="count">{{ totalSales }}</span>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalSales == 1">Venda</span>
            <span v-else>Vendas</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
        v-if="$root.isAdmin() || $root.isManager() || $root.isMilesManager()"
      >
        <div class="card contratos" @click="$router.push(`/vouchers/list`)">
          <div class="ico" style="left: -14px; top: -45px; font-size: 148px">
            <a-icon type="audit" theme="outlined" />
          </div>
          <div class="name">
            <span>
              <font v-if="totalVouchers > 0" class="count">{{
                totalVouchers
              }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalVouchers == 1">Voucher</span>
            <span v-else>Vouchers</span>
          </div>
        </div>
      </div>

      <div class="module" v-if="$root.isAdmin()">
        <div class="card contratos" @click="$router.push(`/customers/list`)">
          <div class="ico" style="left: -34px; font-size: 148px">
            <i class="far fa-address-card"></i>
          </div>
          <div class="name">
            <span>
              <font v-if="totalCustomers > 0" class="count">{{
                totalCustomers
              }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalCustomers == 1">Cliente</span>
            <span v-else>Clientes</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
        v-if="$root.isAdmin()"
      >
        <div class="card contratos" @click="$router.push(`/documents/list`)">
          <div class="ico" style="left: -34px; font-size: 148px">
            <a-icon type="file-text" theme="outlined" />
          </div>
          <div class="name">
            <span>
              <font v-if="totalDocs > 0" class="count">{{ totalDocs }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalDocs == 1">Documento</span>
            <span v-else>Documentos</span>
          </div>
        </div>
      </div>

      <div
        class="module"
        :class="
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
            ? 'readonly'
            : ''
        "
        v-if="$root.isAdmin()"
      >
        <div class="card contratos" @click="$router.push(`/users/list`)">
          <div class="ico" style="left: -34px; font-size: 148px">
            <a-icon type="smile" theme="outlined" />
          </div>
          <div class="name">
            <a-icon type="loading" class="mr-10" v-if="loadingUsers" />
            <span>
              <font v-if="totalUsers > 0" class="count">{{ totalUsers }}</font>
              <span class="count" v-else>0</span>
            </span>
            <span v-if="totalUsers == 1">Usuário</span>
            <span v-else>Usuários</span>
          </div>
        </div>
      </div>
    </div>

    <aDivider />

    <aRow class="mt-30" v-if="$root.isAdmin()" :gutter="40">
      <aCol :span="12">
        <h2>
          <aRow>
            <aCol :span="18">
              <a-icon type="logout" /> ÚLTIMAS EMISSÕES POR USUÁRIO
            </aCol>

            <aCol :span="6">
              <div class="travel-input-outer" style="font-weight: 400">
                <a-select
                  class="travel-input"
                  size="small"
                  placeholder="Selecione um usuário"
                  v-model="milesCardTransactions.filters.users.selected"
                  @change="getMilesCardTransactions"
                  optionFilterProp="txt"
                  show-search
                  style="width: 100%; font-size: 12px; border: 0 !important"
                >
                  <a-select-option
                    v-for="(item, index) of listUsers"
                    :key="index"
                    :value="item.id"
                    :txt="item.first_name"
                  >
                    <div v-if="item.id == -1">
                      {{ item.first_name }}
                      {{ item.last_name }}
                    </div>
                    <div v-else>
                      {{ item.id }} -
                      {{ item.first_name }}
                      {{ item.last_name }}
                    </div>
                  </a-select-option>
                </a-select>
              </div>
            </aCol>
          </aRow>
        </h2>

        <a-table
          class="travel-table last-itens"
          :columns="lastMilesEmissions.collumns"
          :data-source="milesCardTransactions.list"
          :loading="milesCardTransactions.loading"
        >
          <div slot="total_miles" slot-scope="record">
            <div :class="record.is_canceled == '1' ? 'line-through' : ''">
              {{ formatMilesOperation(record) }}
            </div>
          </div>

          <div slot="user" slot-scope="record">
            <a-tooltip>
              <template slot="title">
                {{ record.first_name }}
                {{ record.last_name }}
              </template>
              <center>
                <div id="avatar">
                  <a-avatar
                    v-if="record.avatar"
                    :src="record.avatar"
                    :size="20"
                  />
                  <a-avatar
                    v-else
                    :size="20"
                    style="color: #f56a00; background-color: #fde3cf"
                  >
                    {{ record.first_name.substring(0, 1)
                    }}{{ record.last_name.substring(0, 1) }}
                  </a-avatar>
                </div>
              </center>
            </a-tooltip>
          </div>

          <div slot="value" slot-scope="record">
            {{ record }}
          </div>

          <div slot="operation" slot-scope="record">
            <span v-html="formatOperation(record)" />
            <a-tooltip v-if="record.extra_info">
              <template slot="title">
                {{ record.extra_info }}
              </template>
              <a-icon
                class="ml-5 relative c-pointer"
                style="font-size: 12px; color: #aaa; top: 2px"
                type="info-circle"
              />
            </a-tooltip>
          </div>

          <div slot="contract_id" slot-scope="record">
            <div v-if="record.contract_id > 0">
              <a
                :href="`/contracts/edit/${record.contract_id}`"
                target="_blank"
              >
                {{ record.contract_id }}
              </a>
            </div>
          </div>

          <template slot="created" slot-scope="record">
            {{ record.created }}
          </template>
        </a-table>
      </aCol>
      <aCol :span="12">
        <h2><a-icon type="audit" /> VOUCHERS PENDENTES</h2>

        <a-table
          class="travel-table last-itens"
          :columns="pendingVouchers.collumns"
          :data-source="pendingVouchers.list"
          :loading="pendingVouchers.loading"
        >
          <div slot="id" slot-scope="text">{{ text }}</div>
          <div slot="voyage_id" slot-scope="text">
            <div v-if="text != '0'">{{ text }}</div>
            <div v-if="text == '0'">---</div>
          </div>

          <div slot="issuer" slot-scope="record">
            <a-tooltip>
              <template slot="title">
                {{ record.issuer.first_name }}
                {{ record.issuer.last_name }}
              </template>
              <span id="avatar">
                <a-avatar
                  v-if="record.issuer.avatar"
                  :src="record.issuer.avatar"
                  :size="20"
                />
                <a-avatar
                  v-else
                  :size="20"
                  style="color: #f56a00; background-color: #fde3cf"
                >
                  {{
                    record.issuer.first_name
                      ? record.issuer.first_name.substring(0, 1)
                      : ""
                  }}{{
                    record.issuer.last_name
                      ? record.issuer.last_name.substring(0, 1)
                      : ""
                  }}
                </a-avatar>
              </span>
            </a-tooltip>
          </div>
          <div slot="user" slot-scope="record">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </div>

          <div slot="client_name" slot-scope="record">
            <div
              style="
                max-width: 140px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ record.client_name }}
            </div>
          </div>
          <div slot="value" slot-scope="record">
            {{ formatPriceCurrency(record.value, record.currency) }}
          </div>
          <div slot="receipt_date" slot-scope="record">
            <div v-if="record.receipt_date == '0000-00-00'">---</div>
            <div v-if="record.receipt_date != '0000-00-00'">
              {{ formatDate(record.receipt_date) }}
            </div>
          </div>
          <div slot="expires_at" slot-scope="record">
            {{
              record.expires_at != "0000-00-00"
                ? formatDate(record.expires_at)
                : "---"
            }}
          </div>
        </a-table>
      </aCol>
    </aRow>

    <aRow
      class="mt-30"
      v-if="$root.isAdmin() || $root.isManager() || $root.isMilesManager()"
      :class="
        this.$store.state.userData.id == 46 ||
        this.$store.state.userData.id == 45 ||
        this.$store.state.userData.id == 44
          ? 'none'
          : ''
      "
      :gutter="40"
    >
      <aCol :span="12">
        <h2><a-icon type="credit-card" /> ÚLTIMOS CARTÕES ADICIONADOS</h2>
        <a-table
          class="travel-table last-itens"
          :columns="lastMilesCardsColumns"
          :data-source="lastMilesCards"
          :loading="lastMilesCardsLoading"
        >
          <div slot="id" slot-scope="record">
            {{ record }}
          </div>
          <div slot="supplier" slot-scope="record">
            <div class="dotted-phrase" style="max-width: 130px">
              {{ record.first_name }} {{ record.last_name }}
            </div>
          </div>
          <div slot="quantity" slot-scope="record">
            {{ record }}
          </div>
          <div slot="selling_price" slot-scope="record">R$ {{ record }}</div>
          <div slot="created" slot-scope="record">
            {{ formatDateTime(record) }}
          </div>
          <div slot="action" slot-scope="record">
            <a-tooltip placement="top" title="Ver">
              <a class="view ml-20" @click="viewMilesCard(record)">
                <a-icon slot="prefix" type="eye-svg" style="zoom: 0.034" />
              </a>
            </a-tooltip>
          </div>
        </a-table>
      </aCol>

      <aCol :span="12">
        <h2><a-icon type="like" /> ÚLTIMOS CARTÕES PAGOS</h2>
        <a-table
          class="travel-table last-itens"
          :columns="lastPaidMilesCardsColumns"
          :data-source="lastPaidMilesCards"
          :loading="loadingLastMilesCardsLoading"
        >
          <template slot="batch" slot-scope="record">
            {{ record.transaction_id }}
          </template>

          <template slot="miles_card" slot-scope="record">
            {{ record.id }}
          </template>

          <template slot="supplier" slot-scope="record">
            <div
              style="
                max-width: 130px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ record.miles_card.supplier.first_name }}
              {{ record.miles_card.supplier.last_name }}
            </div>
          </template>

          <template slot="payment_user" slot-scope="record">
            <a-tooltip>
              <template slot="title">
                {{ record.first_name }}
                {{ record.last_name }}
              </template>
              <span id="avatar">
                <a-avatar
                  v-if="record.avatar"
                  :src="record.avatar"
                  :size="20"
                />
                <a-avatar
                  v-else
                  :size="20"
                  style="color: #f56a00; background-color: #fde3cf"
                >
                  {{ record.first_name.substring(0, 1)
                  }}{{ record.last_name.substring(0, 1) }}
                </a-avatar>
              </span>
            </a-tooltip>
          </template>

          <template slot="value" slot-scope="record">
            {{ record }}
          </template>

          <template slot="payment_date" slot-scope="record">
            {{ formatDateTime(record) }}
          </template>

          <div slot="action" slot-scope="record">
            <a-tooltip placement="top" title="Ver">
              <a class="view ml-20" @click="viewPayment(record.miles_card.id)">
                <a-icon slot="prefix" type="eye-svg" style="zoom: 0.034" />
              </a>
            </a-tooltip>
          </div>
        </a-table>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import milesCardsTransactionsMixins from "@/mixins/miles-cards-transactions/milesCardsTransactionsMixins";

export default {
  name: "Dashboard",
  mixins: [formatThings, milesCardsTransactionsMixins],
  data() {
    return {
      openVideoModal: true,
      totalVouchers: 0,
      totalContracts: 0,
      totalDocs: 0,
      totalSuppliers: 0,
      totalCards: 0,
      totalEvents: 0,
      totalSales: 0,
      totalReports: 0,
      totalOpportunities: 0,
      loading: true,
      totalCustomers: 0,
      loadingLastMilesCardsLoading: false,
      loadingCustomers: true,
      lastMilesCards: [],
      lastPaidMilesCards: [],
      listCustomers: [],
      totalUsers: 0,
      loadingUsers: true,
      listUsers: [],
      selectedUser: undefined,
      loadingDocs: true,
      loadingReports: true,
      loadingSuppliers: true,
      loadingCards: true,
      loadingLastPaidMilesCards: true,
      listCards: [],
      lastMilesCardsColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
        },
        {
          title: "Fornecedor",
          dataIndex: "supplier",
          key: "supplier",
          scopedSlots: { customRender: "supplier" },
        },
        {
          title: "Qtd. Milhas",
          dataIndex: "total_miles",
          key: "total_miles",
        },
        {
          title: "Valor",
          dataIndex: "selling_price",
          key: "selling_price",
          scopedSlots: { customRender: "selling_price" },
        },
        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      lastPaidMilesCardsColumns: [
        {
          title: "Lote",
          key: "batch",
          scopedSlots: { customRender: "batch" },
        },
        {
          title: "Cartão",
          dataIndex: "miles_card",
          key: "miles_card",
          class: "miles_card",
          scopedSlots: { customRender: "miles_card" },
        },
        {
          title: "Fornecedor",
          key: "supplier",
          scopedSlots: { customRender: "supplier" },
          width: 140,
        },
        {
          title: "Parcela",
          dataIndex: "installment_number",
          key: "installment_number",
        },
        {
          title: "Valor (R$)",
          dataIndex: "value",
          key: "value",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Data pagamento",
          dataIndex: "payment_date",
          key: "payment_date",
          scopedSlots: { customRender: "payment_date" },
        },
        {
          title: "Pago por",
          dataIndex: "payment_user",
          key: "payment_user",
          scopedSlots: { customRender: "payment_user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      lastMilesCardsList: [],
      lastMilesCardsLoading: false,
      pendingVouchers: {
        loading: false,
        list: [],
        collumns: [
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            class: "id",
            width: 40,
          },
          {
            title: "ID Voyage",
            dataIndex: "voyage_id",
            key: "voyage_id",
            width: 80,
          },
          {
            title: "Cliente",
            width: 140,
            scopedSlots: { customRender: "client_name" },
          },

          {
            title: "Valor",
            scopedSlots: { customRender: "value" },
          },
          {
            title: "Validade",
            scopedSlots: { customRender: "expires_at" },
          },
          {
            title: "Emitido por:",
            scopedSlots: { customRender: "issuer" },
          },
          {
            title: "",
            key: "action",
            align: "right",
            scopedSlots: { customRender: "action" },
          },
        ],
      },
      lastMilesEmissions: {
        collumns: [
          {
            title: "ID Operação",
            dataIndex: "milescard_transaction_id",
            key: "milescard_transaction_id",
            class: "milescard_transaction_id",
          },
          {
            title: "Qtd. Milhas",
            scopedSlots: {
              customRender: "total_miles",
            },
          },

          {
            title: "Operação",
            scopedSlots: {
              customRender: "operation",
            },
          },
          {
            title: "ID Contrato",
            scopedSlots: {
              customRender: "contract_id",
            },
          },
          {
            title: "",
            dataIndex: "user",
            key: "user",
            scopedSlots: {
              customRender: "user",
            },
          },
          {
            title: "Data/Hora",
            scopedSlots: {
              customRender: "created",
            },
          },
          {
            title: "",
            align: "right",
            fixed: "right",
            scopedSlots: { customRender: "action" },
          },
        ],
      },
    };
  },
  computed: {
    routeTab() {
      return {
        title: `Dashboard`,
      };
    },
  },
  beforeMount() {
    setTimeout(() => {
      this.openVideoModal = true;
    }, 1000);

    let dashboardFilters = "";

    if (this.$root.isManager() || this.$root.isUser())
      dashboardFilters = `?user_id=${this.$store.state.userData.id}`;

    this.$http.get(`/dashboard/panel${dashboardFilters}`).then(({ data }) => {
      this.totalContracts = data.contracts.total;
      this.totalOpportunities = data.opportunities.total;
      this.totalSuppliers = data.suppliers.total;
      this.totalCustomers = data.customers.total;
      this.totalDocs = data.documents.total;
      this.totalCards = data.miles_cards.total;
      this.totalUsers = data.users.total;
      this.totalVouchers = data.vouchers.total;
      this.totalEvents = data.events.total;
      this.totalSales = data.sales.total;
    });

    if (
      this.$root.isAdmin() ||
      this.$root.isManager() ||
      this.$root.isMilesManager()
    ) {
      // CARTÕES PAGOS
      this.$http
        .post(
          "/payment/list?page=1&per_page=30&status=paid&order=descend&order-by=payment_date"
        )
        .then(({ data }) => {
          this.lastPaidMilesCards = data.data;
          this.loadingLastPaidMilesCards = false;

          this.milesCardTransactions.pagination.perPage = 30;
          this.milesCardTransactions.filters.isCanceled = "0";
          this.milesCardTransactions.filters.operation.selected = ["subtract"];
          this.getMilesCardTransactions();
        })
        .catch(() => {
          this.loadingLastPaidMilesCards = false;
        });

      // VOUCHERS

      this.pendingVouchers.loading = true;

      // VOUCHERS PENDENTES
      this.$http
        .post("/miles-card-voucher/list?page=1&per_page=30&status=Pendente")
        .then(({ data }) => {
          this.pendingVouchers.list = data.data;
          this.pendingVouchers.loading = false;
        })
        .catch(() => {
          this.pendingVouchers.loading = false;
        });

      // CARTÕES
      this.$http
        .post("/miles-card/list?page=1&per_page=30")
        .then(({ data }) => {
          this.lastMilesCards = data.data;
          this.totalCards = data.meta.total;
          this.loadingCards = false;
        })
        .catch(() => {
          this.totalCards = 0;
          this.loadingCards = false;
        });
    }

    if (this.$root.isAdmin()) {
      this.$http
        .post("/user/list?page=1&per_page=100&order=ascend&order-by=first_name")
        .then(({ data }) => {
          this.totalUsers = data.meta.total;
          this.listUsers = data.data;
          this.listUsers.push({
            first_name: "Todos usuários",
            last_name: "",
            id: -1,
          });
          this.listUsers.reverse();
          this.loadingUsers = false;
        })
        .catch(() => {
          this.totalUsers = 0;
          this.loadingUsers = false;
        });
    }
  },
  methods: {
    viewMilesCard(milesCard) {
      this.$router.push(`/miles-cards/list`);
      this.$store.state.viewMilesCard = true;
      this.$store.state.theMilesCard = milesCard;
    },
    viewPayment(id) {
      this.$router.push(`/miles-finances/payments/view/${id}`);
    },
  },
};
</script>

<style lang="sass">
.module.readonly
  opacity: 0.7
  filter: grayscale(100%)
</style>
